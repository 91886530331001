<template>
    <div class="card rounded-xl shadow overflow-hidden">
        <div class="card-header font-weight-bolder border-bottom-0 shadow-sm py-2">
            <b-spinner small
                       v-if="isLoading">
            </b-spinner>
            <span class="badge badge-light bg-white border"
                  v-else>
                {{ count }}
            </span>
            Reviews from users
        </div>
        <ul class="list-group list-group-flush">
            <evaluation-item v-for="evaluation in evaluations"
                             v-bind:key="evaluation._id"
                             v-bind:review="evaluation">
            </evaluation-item>
        </ul>
    </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import EvaluationItem from "./EvaluationCard/EvaluationItem";
export default {
    name: "EvaluationCard",
    components: {
        EvaluationItem,
        BSpinner,
    },
    props: {
        organizer: {
            type: Object,
        },
    },
    data () {
        return {
            isLoading: false,
        };
    },
    computed: {
        organizerId () {
            return this.organizer?._id ?? "";
        },
        evaluations () {
            return this.$store.getters["evaluation/itemsByOrganizer"](this.organizerId);
        },
        rating () {
            return parseFloat(this.organizer?.profile?.organizerOverall) ?
                parseFloat(this.organizer?.profile?.organizerOverall).toFixed(1) :
                "NA";
        },
        count () {
            return this.organizer?.profile?.organizerResponseCount ?
                parseFloat(this.organizer?.profile?.organizerResponseCount).toFixed(0) :
                "NA";
        },
    },
    methods: {
        async loadEvaluations () {
            try {
                this.isLoading = true;
                await this.$store.dispatch("evaluation/loadByOrganizer", this.organizerId)
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
    },
    mounted () {
        this.loadEvaluations();
    },
    watch: {
        organizerId (newVal) {
            if (!newVal) {
                return;
            }
            this.loadEvaluations();
        },
    }
}
</script>

<style lang="scss" scoped>
.card-header {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}
</style>
