<template>
    <li class="list-group-item pb-0 px-0"
        v-observe-visibility="visibilityChanged">
        <div class="px-3 pb-2">
            <reviewer-tag class="mb-3"
                          v-bind:is-loading="isLoading"
                          v-bind:created-by="createdBy">
            </reviewer-tag>
            <p class="font-weight-normal mb-3">
                {{ feedBack }}
            </p>
            <dl class="row mb-1">
                <dt class="col-sm-4 text-nowrap">
                    Impression
                </dt>
                <dd class="col-sm-8 d-flex flex-nowrap justify-content-between">
                    <star-ratings v-bind:rating="impression"></star-ratings>
                    <span>{{ impression }} / 5</span>
                </dd>

                <dt class="col-sm-4 text-nowrap">
                    CP Ratio
                </dt>
                <dd class="col-sm-8 d-flex flex-nowrap justify-content-between">
                    <star-ratings v-bind:rating="cpRatio"></star-ratings>
                    <span>{{ cpRatio }} / 5</span>
                </dd>

                <dt class="col-sm-4 text-nowrap">
                    Joyfulness
                </dt>
                <dd class="col-sm-8 d-flex flex-nowrap justify-content-between">
                    <star-ratings v-bind:rating="interesting"></star-ratings>
                    <span>{{ interesting }} / 5</span>
                </dd>
            </dl>
            <p class="text-right mb-0">
                reviewed <span class="font-weight-bold">{{ createdAtDistanceToNow }}</span><br>
                <small class="text-secondary font-weight-normal">on {{ createdAt }}</small>
            </p>
        </div>
        <div class="d-flex flex-nowrap align-items-center py-2 px-3"
             v-bind:class="isApprovedColor">
            <div class="flex-grow-1 font-weight-bold"
                 v-bind:class="isApprovedTextColor">
                {{ isApprovedString }}
            </div>
            <div>
                <button class="btn btn-sm btn-success shadow-sm mr-1"
                        v-if="!isApproved"
                        v-bind:class="{ 'btn-loading': isLoading }"
                        v-on:click="approve">
                    Approve👍
                </button>
                <button class="btn btn-sm btn-danger shadow-sm"
                        v-if="isApproved === true || isApproved === null"
                        v-bind:class="{ 'btn-loading': isLoading }"
                        v-on:click="reject">
                    Reject👎
                </button>
            </div>
        </div>
    </li>
</template>

<script>
import { apiUrl, apiVersion, } from "../../../../config";
import format from "date-fns/format";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ObserveVisibility } from "vue-observe-visibility";
import { getUserById } from "../../../../workers/users.worker";
export default {
    name: "EvaluationItem",
    directives: {
        "observe-visibility": ObserveVisibility,
    },
    components: {
        StarRatings: () => import("./StarRatings"),
        ReviewerTag: () => import("../../../Reviews/ReviewItem/ReviewerTag"),
    },
    props: {
        review: {
            type: Object,
        },
    },
    data () {
        return {
            isLoading: false,
            isCreatedByLoaded: false,

            createdBy: null,
        };
    },
    computed: {
        id () {
            return this.review?._id ?? "";
        },
        isApproved () {
            return this.review?.isApproved ?? null;
        },
        isApprovedColor () {
            return this.isApproved === true ?
                "bg-success" :
                this.isApproved === false ?
                    "bg-danger" :
                    "bg-light";
        },
        isApprovedTextColor () {
            return this.isApproved === true ?
                "text-white" :
                this.isApproved === false ?
                    "text-white" :
                    "text-dark";
        },
        isApprovedString () {
            return this.isApproved === true ?
                "👍 Approved" :
                this.isApproved === false ?
                    "👎 Rejected" :
                    "❓ Pending";
        },
        createdAtDistanceToNow () {
            return this.review?.createdAt ?
                formatDistanceToNow(new Date(this.review.createdAt), { addSuffix: true }) :
                "Not available";
        },
        createdAt () {
            return this.review?.createdAt ?
                format(new Date(this.review.createdAt), "PPp") :
                "Not available";
        },
        answers () {
            return this.review?.answers ?? {};
        },
        activityDate () {
            return this.answers?.activityDate ?? "";
        },
        activityName () {
            return this.answers?.activityAttended ?? "";
        },
        feedBack () {
            return this.answers?.feedback ?? "";
        },
        impression () {
            return this.answers?.impression ?
                parseFloat(this.answers.impression).toFixed(2) :
                "N.A.";
        },
        cpRatio () {
            return this.answers?.cpRatio ?
                parseFloat(this.answers.cpRatio).toFixed(2) :
                "N.A.";
        },
        interesting () {
            return this.answers?.interesting ?
                parseFloat(this.answers.interesting).toFixed(2) :
                "N.A.";
        },
        origin () {
            return this.answers?.origin ?? "";
        },
        createdById () {
            return this.review?.createdBy ?? "";
        },
        createdByName () {
            return `${ this.createdBy?.name ?? "" } (+${ this.createdBy?.countryCode } ${ this.createdBy?.phone })`;
        },
        avatar () {
            return this.createdBy?.avatar ?
                `${ apiUrl }/${ apiVersion }/user/${ this.createdBy }/avatar` :
                "";
        },
    },
    methods: {
        visibilityChanged (isVisible) {
            if (isVisible && !this.isCreatedByLoaded) {
                this.loadCreatedBy();
            }
        },
        async loadCreatedBy () {
            try {
                this.isLoading = true;
                this.createdBy = await getUserById(this.createdById);
                this.isCreatedByLoaded = true;
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async approve () {
            try {
                this.isLoading = true;
                await this.$store.dispatch("evaluation/approve", this.id);
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async reject () {
            try {
                this.isLoading = true;
                await this.$store.dispatch("evaluation/reject", this.id);
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
