<template>
    <side-panel class="user-details">
        <div class="position-sticky title d-flex flex-row justify-content-between p-3">
            <div class="mr-1">
                <router-link class="btn btn-outline-dark border-0 rounded-lg"
                             v-bind:to="`/organizers`">
                    <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                </router-link>
            </div>
            <div class="flex-grow-1">
                <h3 class="font-weight-bolder text-right mb-1">
                    {{ nickname }}
                </h3>
                <h5 class="text-right mb-1">
                    (+{{ countryCode }} {{ phone }})
                </h5>
                <p class="text-dark text-right mb-0">
                    <strong class="text-uppercase">{{ role }}</strong> joined {{ timeCreated }}
                </p>
            </div>
        </div>
        <div class="details pb-3 px-3">
            <organizer-card class="mb-4"
                            v-bind:organizer="organizer">
            </organizer-card>
            <evaluation-card v-bind:organizer="organizer"></evaluation-card>
        </div>
        <div class="position-sticky actions p-3 d-flex justify-content-between">
            <button class="btn btn-block btn-warning shadow-sm rounded-pill m-0 mr-1"
                    v-bind:class="{ 'btn-loading': isEditing }"
                    v-on:click="isEditUserModalOpened = true">
                ✏️ Update
            </button>
            <button class="btn btn-block btn-danger shadow-sm rounded-pill m-0 ml-1"
                    v-bind:class="{ 'btn-loading': isEditing }"
                    v-on:click="isReauthenticateModalOpened = true">
                🗑️ Delete
            </button>
        </div>

        <modal-user v-if="adminRole === 'admin'"
                    v-bind:user="organizer"
                    v-model="isEditUserModalOpened">
        </modal-user>
        <modal-re-authenticate challenge-type="password"
                               v-if="adminRole === 'admin'"
                               v-bind:email="adminEmail"
                               v-bind:callback="openDeleteUserModal"
                               v-model="isReauthenticateModalOpened">
            <p>You are conducting a sensitive action. Please authenticate yourself. </p>
        </modal-re-authenticate>
        <modal-delete-confirm field="name"
                              v-if="adminRole === 'admin'"
                              v-bind:target="organizer"
                              v-bind:callback="deleteUser"
                              v-model="isDeleteUserModalOpened">
        </modal-delete-confirm>
    </side-panel>
</template>

<script>
import mongoObjectIdToDate from "@/utilities/mongoObjectIdToDate";
import { formatDistanceToNow, parseISO, } from "date-fns";
import EvaluationCard from "@/components/Organizers/OrganizerDetails/EvaluationCard";
export default {
    name: "OrganizerDetails",
    components: {
        EvaluationCard,
        SidePanel: () => import("@/components/SidePanel"),
        OrganizerCard: () => import("@/components/Organizers/OrganizerDetails/OrganizerCard"),
        ModalUser: () => import("@/components/Users/ModalUser"),
        ModalDeleteConfirm: () => import("@/components/modals/ModalDeleteConfirm"),
        ModalReAuthenticate: () => import("@/components/modals/ModalReAuthenticate"),
    },
    props: {
        "organizerId": {
            type: String,
        },
    },
    data () {
        return {
            isLoading: true,
            isEditing: false,

            isReauthenticateModalOpened: false,
            isEditUserModalOpened: false,
            isDeleteUserModalOpened: false,
        };
    },
    computed: {
        adminRole () {
            return this.$store.getters["admin/info"].role;
        },
        adminEmail () {
            return this.$store.getters["admin/info"].email;
        },
        organizer () {
            return this.$store.getters["users/itemById"](this.organizerId);
        },
        name () {
            return this.organizer?.name ?? "";
        },
        nickname () {
            return this.organizer?.profile?.nickname ?? "";
        },
        role () {
            return this.organizer?.role ?? "";
        },
        countryCode () {
            return this.organizer?.countryCode ?? "";
        },
        phone () {
            return this.organizer?.phone ?? "";
        },
        timeCreated () {
            if (this.organizer) {
                if (this.organizer.createdAt) {
                    return formatDistanceToNow(
                        parseISO(this.organizer.createdAt),
                        {
                            "addSuffix": true
                        }
                    );
                }
                return formatDistanceToNow(
                    parseISO(mongoObjectIdToDate(this.organizer._id)),
                    { "addSuffix": true, }
                );
            }

            return "";
        },
        gender () {
            return this.organizer?.profile?.gender ?? "";
        },
        ageGroup () {
            return this.organizer?.profile?.ageGroup ?? "";
        },
        introduction () {
            return this.organizer?.profile?.introduction ?? "NA";
        },
        referrer () {
            return this.organizer?.profile?.referrer ?? "";
        },
        account () {
            if (
                this.organizer &&
                this.organizer.countryCode &&
                this.organizer.phone
            ) {
                if (this.$store.state.whatsapp.accountsByUser[`${ this.organizer.countryCode }${ this.organizer.phone }`] === undefined) {
                    this.getAccountByUserId();
                }
                return this.$store.state.whatsapp.accountsByUser[`${ this.organizer.countryCode }${ this.organizer.phone }`];
            }
            return {};
        },
        chats () {
            return this.account && Array.isArray(this.account.chats) ?
                this.account.chats :
                [];
        },
        verified () {
            return this.organizer.verified;
        },
        inRequiredGroups () {
            return this.chats
                .reduce(
                    (acc, { tracking }) => acc || tracking,
                    false
                );
        },
    },
    methods: {
        async getAccountByUserId () {
            if (!this.isLoading) {
                this.isLoading = true;
                await this.$store.dispatch(
                    "whatsapp/loadAccount",
                    `${ this.organizer.countryCode }${ this.organizer.phone }`
                );
                this.isLoading = false;
            }
        },
        openDeleteUserModal () {
            this.isDeleteUserModalOpened = true;
        },
        async deleteUser () {
            try {
                this.isEditing = true;
                await this.$store.dispatch("users/deleteUser", this.organizer._id);
                this.$router.push("/organizers");
            } catch (e) {
                console.error(e);
            } finally {
                this.isEditing = false;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    top: 0;

    background-color: rgba(255, 255, 255, 1);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    z-index: 3;
}

.actions {
    bottom: 0;
    z-index: 3;
}

.row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}

.gender-card {
    &.male {
        background-color: #2766f6;

        .logo {
            color: #0843c8;
        }
    }

    &.female {
        background-color: #fc363b;

        .logo {
            color: #e20309;
        }
    }
}

.age-group-card {
    background-color: rgba(255, 132, 51, 1);

    .logo {
        color: rgba(230, 91, 0, 1);
    }
}

.location-card {
    background-color: rgba(106, 110, 115, 1);

    .logo {
        color: rgba(69, 72, 75, 1);
    }
}

.intro-card {
    background-color: rgba(110, 168, 254, 1);

    .logo {
        color: rgba(13, 110, 253, 1);
    }

    .introduction {
        white-space: pre-line;
    }
}

.referrer-card {
    background-color: rgba(23, 162, 184, 1);

    .logo {
        color: rgba(15, 102, 116, 1);
    }
}
</style>
